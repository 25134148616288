import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { filter, first, map } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { User } from './classes/user/user';
import { BaseComponent, SuperRequired } from './shared/modules/base-component/base.component';
import { MainConstants } from './config/app.constant';
import { LanguageService } from './shared/services/language.service';

/** Main component */
@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends BaseComponent implements OnInit, OnDestroy {
  loggedInUser: User;
  showHeader: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    private languageService: LanguageService
  ) {
    super();

    this.languageService.init();
  }

  ngOnInit(): SuperRequired.OnInitFunc {
    this.listenForNavigationEndChanges();

    return super.ngOnInit();
  }

  prepareRouterTransition(outlet: RouterOutlet): void {
    return outlet.activatedRouteData.animation;
  }

  private listenForNavigationEndChanges(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let child = this.route.firstChild;
          while (child) {
            if (child.firstChild) {
              child = child.firstChild;
            } else if (child.snapshot) {
              return child.snapshot;
            } else {
              return null;
            }
          }

          return null;
        }),
        untilDestroyed(this)
      )
      .subscribe((snapshot: ActivatedRouteSnapshot) => {
        if (!MainConstants.hideHeaderTitles.includes(snapshot.data.title)) {
          this.showHeader = true;
        }
        this.setTitle(snapshot);
      });
  }

  private setTitle(snapshot: ActivatedRouteSnapshot): void {
    if (snapshot.data && snapshot.data.title) {
      this.translate
        .get(snapshot.data.title)
        .pipe(first())
        .subscribe((translated) => {
          this.titleService.setTitle(translated);
        });
    }
  }

  /* eslint-disable @typescript-eslint/member-ordering */
  ngOnDestroy(): SuperRequired.OnDestroyFunc {
    return super.ngOnDestroy();
  }
}
