import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

/** Http Service Layer */
@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(private http: HttpClient) {}

  /** generate url for backend call */
  generateFullUrl(url: string): string {
    return environment.apiUrl + url;
  }

  /** generate url query params for backend call */
  generateUrlQueryParams(urlParams: Record<string, string> = null): HttpParams {
    let params = new HttpParams();

    if (urlParams) {
      Object.keys(urlParams).forEach((property: string) => {
        if (property in urlParams) {
          params = params.append(property, urlParams[property]);
        }
      });
    }

    return params;
  }

  get(url: string, urlParams: Record<string, string> = null): Observable<any> {
    return this.http.get(this.generateFullUrl(url), {
      params: this.generateUrlQueryParams(urlParams),
    });
  }

  post(url: string, data: any): Observable<any> {
    return this.http.post(this.generateFullUrl(url), data);
  }

  put(url: string, data: any): Observable<any> {
    return this.http.put(this.generateFullUrl(url), data);
  }

  patch(url: string, data: any): Observable<any> {
    return this.http.patch(this.generateFullUrl(url), data);
  }

  delete(url: string): Observable<any> {
    return this.http.delete(this.generateFullUrl(url));
  }

  sendRequest(request: HttpRequest<any>): Observable<any> {
    return this.http.request(request);
  }
}
