import { Injectable } from '@angular/core';
import { Language, LanguageConfig } from '../../config/language.config';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  constructor(private translate: TranslateService) {}

  init(): void {
    // only HU is used, which is the default language
    const defaultLang = LanguageConfig.DEFAULT_LANGUAGE;

    this.translate.setDefaultLang(defaultLang);
    this.translate.addLangs(LanguageConfig.LANGUAGES);

    this.translate.use(defaultLang);
  }

  get currentLang(): Language {
    return this.translate.currentLang as Language;
  }
}
