import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { HttpModule } from './shared/modules/http/http.module';
import { GlobalLoaderModule } from './shared/modules/global-loader/global-loader.module';
import { HeaderModule } from './shared/modules/header/header.module';
import { HttpMultiLoaderFactory } from './shared/utils/http-multi-loader.util';
import { AppInjector } from './app.injector.service';
import { BaseComponentModule } from './shared/modules/base-component/base-component.module';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';
import hu from '@angular/common/locales/hu';
import { registerLocaleData } from '@angular/common';

registerLocaleData(hu);

/** Main module, imports app wide modules, providers, bootstrap */
@NgModule({
  declarations: [AppComponent],
  imports: [
    HeaderModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    HttpModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpMultiLoaderFactory,
        deps: [HttpClient],
      },
    }),
    GlobalLoaderModule,
    BaseComponentModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'hu-HU' },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  // eslint-disable-next-line
  constructor(injector: Injector, _: Sentry.TraceService) {
    AppInjector.setInjector(injector);
  }
}
