<div [class.mobile]='isMobile'
     class='app-header'>
    <div class='app-header-content text-center'>
        <svg-icon
            class="me-2 back-icon"
            [src]="'assets/image/back_arrow_icon_light.svg'">
        </svg-icon>
        <div>
            <img class="app-header-content-logo"
                 [src]="'assets/image/mcc_plus_logo.svg'"
                 [alt]="">
        </div>
    </div>
</div>
