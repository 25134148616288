import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { keys } from 'lodash-es';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BaseComponentMessages } from './classes/base-component-messages';
import { DeviceService } from '../../services/device.service';
import { AppInjector } from '../../../app.injector.service';

export enum SuperRequired {
  OnInitFunc = 'OnInitFunc',
  OnDestroyFunc = 'OnDestroyFunc',
}

@UntilDestroy()
@Component({
  template: '',
})
export class BaseComponent implements OnInit, OnDestroy {
  public isMobile: boolean;
  public isTablet: boolean;
  public isDesktop: boolean;
  public isBrowserSupported: boolean;

  protected deviceService: DeviceService;
  protected translate: TranslateService;

  // example of usage can be found in BaseComponentMessages interface file
  protected messages: BaseComponentMessages;

  constructor() {
    const injector = AppInjector.getInjector();
    this.deviceService = injector.get<DeviceService>(DeviceService);
    this.translate = injector.get<TranslateService>(TranslateService);

    this.isMobile = this.deviceService.isMobile;
    this.isTablet = this.deviceService.isTablet;
    this.isDesktop = this.deviceService.isDesktop;
    this.isBrowserSupported = this.deviceService.isBrowserSupported();
  }

  /**
   * Azért lett ilyen fura megoldás, mivel az angular sajnos nem támogatja alapból,
   * hogy szóljon neked az IDE, hogy nem hívtad meg az ős osztály lifecycle hook-ját.
   * Emiatt a megoldás az lett, hogy visszatérési értékként egy az ősosztályban létező
   * enumot vár a függvény. Így, ha ebből származtatsz egy komponenst, és meghívod
   * benne valamelyik lifecycle hook-ot, errort fogsz kapni, mivel nem adja vissza
   * az elvárt értéket. Így ki tudjuk kényszeríteni az ős lifecycle hookjának hívását.
   *
   * @param skip
   * Opcionális, de közös dologkat ebben az ágban lehet majd lekezelni.
   */
  ngOnInit(skip?: boolean): SuperRequired.OnInitFunc {
    if (!skip) {
      // common onInit logic
      this.initTranslateMessages();
    }
    return SuperRequired.OnInitFunc;
  }

  private initTranslateMessages(): void {
    this.translateMessages();
    this.translate.onLangChange.pipe(untilDestroyed(this)).subscribe(() => {
      this.translateMessages();
    });
  }

  private translateMessages(): void {
    keys(this.messages).forEach((key) => {
      if (key in this.messages) {
        this.translate
          .get(key, this.messages[key])
          .pipe(untilDestroyed(this))
          .subscribe((translated) => {
            this.messages[key] = translated;
          });
      }
    });
  }

  /* eslint-disable @typescript-eslint/member-ordering */
  ngOnDestroy(skip?: boolean): SuperRequired.OnDestroyFunc {
    if (!skip) {
      // common onDestroy logic
    }
    return SuperRequired.OnDestroyFunc;
  }
}
