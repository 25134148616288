/** Main constants should store here */

export enum PageName {
  Contents = 'Contents',
}

export const MainConstants = {
  dateFormatShort: 'yyyy. MMMM dd.',
  dateFormatLong: 'yyyy. MMMM dd. (HH:mm)',
  dateFormatWithoutYear: 'MMMM dd.',
  avatarPlaceholder: 'assets/image/avatar_placeholder.svg',
  previewParam: 'token',
  livePreviewParam: 'x-craft-live-preview',
  hideHeaderTitles: ['Products.PageTitle', 'Questions.PageTitle', 'Tutorials.PageTitle'],
  platformName: 'angular',
};
