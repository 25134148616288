import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BaseComponent, SuperRequired } from '../base-component/base.component';

@UntilDestroy()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() hasStickySideBar: boolean;

  constructor(private router: Router) {
    super();
  }

  ngOnInit(): SuperRequired.OnInitFunc {
    return super.ngOnInit();
  }

  /* eslint-disable @typescript-eslint/member-ordering */
  ngOnDestroy(): SuperRequired.OnDestroyFunc {
    return super.ngOnDestroy();
  }
}
