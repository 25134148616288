import { catchError, tap } from 'rxjs/operators';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { GlobalLoaderService } from '../global-loader/services/global-loader.service';
import { environment } from '../../../../environments/environment';
import { HttpService } from './http.service';
import { ApiData } from '../../../classes/api-data';
import { ApiDataService } from '../../services/api-data.service';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
  apiData: ApiData;

  constructor(
    private loader: GlobalLoaderService,
    private httpService: HttpService,
    private apiDataService: ApiDataService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const apiCall = req.urlWithParams.startsWith(environment.apiUrl);
    const requestClone = req.clone();

    return next.handle(requestClone).pipe(
      tap((event) => {
        if (event instanceof HttpResponse && apiCall) {
          this.loader.hideLoaderForUrl(requestClone.url);

          const apiVersion = event.headers.get('api-version') ?? '';
          const apiEnv = event.headers.get('api-env') ?? '';

          if (this.apiData?.apiVersion !== apiVersion || this.apiData?.apiEnv !== apiEnv) {
            this.apiDataService.setApiData({ apiVersion, apiEnv });
          }
        }
      }),
      catchError((error) => {
        if (apiCall) {
          this.loader.hideLoaderForUrl(requestClone.url);
        }

        if (error instanceof HttpErrorResponse) {
          return this.handleError(error);
        } else {
          return throwError(() => error);
        }
      })
    );
  }

  private handleError(err: HttpErrorResponse): Observable<any> {
    console.log(err.status);

    return throwError(() => err);
  }
}
