import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { CspDirectiveName, ICspDirective, ICspSource, IEnvironmentKey } from '../../../environments/classes/ICsp';

@Injectable({
  providedIn: 'root',
})
export class CspService {
  generateHeader(): void {
    const htmlHead = document.getElementsByTagName('head')[0];

    let cspContent = '';

    for (const [key, value] of Object.entries(environment.csp)) {
      cspContent += `\n\t${key}`;

      for (const source of value) {
        if (CspService.isIEnvironmentKey(source)) {
          cspContent += ` ${environment[source]}`;
        } else {
          cspContent += ` ${source}`;
        }
      }

      cspContent += `;`;
    }
    cspContent += `\n`;

    const metaCsp = document.createElement('meta');
    metaCsp.setAttribute('name', 'csp');
    metaCsp.setAttribute('http-equiv', 'Content-Security-Policy');
    metaCsp.setAttribute('content', cspContent);

    htmlHead.append(metaCsp);
  }

  static isIEnvironmentKey(key: ICspSource): key is IEnvironmentKey {
    return environment.hasOwnProperty(<IEnvironmentKey>key);
  }

  static mergeCsp(cspTarget: ICspDirective, cspOverride: Partial<ICspDirective>): ICspDirective {
    for (const [key, value] of Object.entries(cspOverride)) {
      cspTarget[<CspDirectiveName>key] = [...cspTarget[<CspDirectiveName>key], ...value];
    }

    return cspTarget;
  }
}
