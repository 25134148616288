import { HttpClient } from '@angular/common/http';
import { ITranslationResource, MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';

class HttpLoaderResource implements ITranslationResource {
  suffix = '.json';
  prefix: string;

  constructor(location: string) {
    this.prefix = `./assets/i18n/${location}/`;
  }
}

/** get translate files */
export const HttpMultiLoaderFactory = (http: HttpClient): MultiTranslateHttpLoader => {
  return new MultiTranslateHttpLoader(http, [
    new HttpLoaderResource('common'),
    new HttpLoaderResource('contents'),
    new HttpLoaderResource('events'),
    new HttpLoaderResource('products'),
    new HttpLoaderResource('learnings'),
    new HttpLoaderResource('questions'),
    new HttpLoaderResource('tutorials'),
  ]);
};
