import { RouterModule, Routes } from '@angular/router';

/*eslint-disable*/
const routes: Routes = [
  {
    path: 'contents',
    loadChildren: () => import('./pages/contents/contents.module').then((mod) => mod.ContentsModule),
  },
  {
    path: 'events',
    loadChildren: () => import('./pages/events/events.module').then((mod) => mod.EventsModule),
  },
  {
    path: 'products',
    loadChildren: () => import('./pages/products/products.module').then((mod) => mod.ProductsModule),
  },
  {
    path: 'learnings',
    loadChildren: () => import('./pages/learnings/learnings.module').then((mod) => mod.LearningsModule),
  },
  {
    path: 'questions',
    loadChildren: () => import('./pages/quiz-question/quiz-question.module').then((mod) => mod.QuizQuestionModule),
  },
  {
    path: 'tutorials',
    loadChildren: () => import('./pages/tutorial/tutorial.module').then((mod) => mod.TutorialModule),
  },

  { path: '**', redirectTo: 'contents' },
];

/** Main routing module */
export const AppRoutingModule = RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' });
