// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
import { IEnvironment } from './classes/IEnvironment';
import cspDefault from './classes/CspDefault';
import { CspService } from '../app/shared/services/csp.service';

export const environment: IEnvironment = {
  name: 'LOCAL',
  production: false,

  // apiUrl: 'https://mcc-mock.attrecto-dev.com/api/v1', // MOCK-URL
  apiUrl: 'https://api.mcc-progress.attrecto-dev.com/api/v1', //ProgressApp-URL

  useCookieAuth: true,
  logEntities: true,

  sentryConfig: {
    dsn: 'https://22d881b9ddcf46e2a386303586598fa1@o1230157.ingest.sentry.io/6554598',
    tracingOrigins: ['localhost'],
  },

  cookieDomain: '.attrecto-dev.com',
  cookieServeUrl: 'mcc-cms-preview.attrecto-dev.com',
  cookieServeMode: 'https',

  csp: CspService.mergeCsp(cspDefault, {}),
};
