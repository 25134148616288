import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { GlobalLoaderService } from '../global-loader/services/global-loader.service';
import { LanguageService } from '../../services/language.service';
import { MainConstants } from '../../../config/app.constant';
import packageJson from '../../../../../package.json';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  constructor(private loader: GlobalLoaderService, private languageService: LanguageService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let requestClone = request.clone();

    const apiCall = requestClone.urlWithParams.startsWith(environment.apiUrl);

    if (apiCall) {
      requestClone = this.setRequestHeaders(requestClone);
      this.loader.showLoaderForUrl(requestClone.url);
    }

    return next.handle(requestClone);
  }

  private setRequestHeaders(req: HttpRequest<any>): HttpRequest<any> {
    // TODO: SetHeaders

    const headers: { [key: string]: string } = {
      'Accept-Language': this.languageService.currentLang,
    };

    if (environment.name !== 'LOCAL') {
      headers['app-version'] = packageJson.version;
      headers['app-env'] = environment.name;
      headers['platform'] = MainConstants.platformName;
    }

    return req.clone({
      setHeaders: headers,
      withCredentials: false, // no credentials yet
    });
  }
}
