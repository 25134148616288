import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { BrowserTracing } from '@sentry/tracing';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import packageJson from '../package.json';

Sentry.init({
  enabled: environment.name !== 'LOCAL',
  dsn: environment.sentryConfig.dsn,
  release: `craft-frontend@${packageJson.version}`,
  integrations: [
    new BrowserTracing({
      tracingOrigins: environment.sentryConfig.tracingOrigins,
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));
