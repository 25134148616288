import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface LoaderState {
  show: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class GlobalLoaderService {
  private requestsInProgress: { [key: string]: number } = {};

  loaderSubject = new Subject<LoaderState>();
  loaderState = this.loaderSubject.asObservable();
  showCount = 0;

  show(): void {
    this.showCount++;
    this.emitShow();
  }

  hide(): void {
    if (this.showCount > 0) {
      this.showCount--;
      this.emitShow();
    } else {
      console.warn('unnecessary loader hide! showCount was already 0');
    }
  }

  emitShow(): void {
    this.loaderSubject.next({ show: this.showCount > 0 } as LoaderState);
  }

  showLoaderForUrl(url: string): void {
    if (this.requestsInProgress[url] === undefined) {
      this.requestsInProgress[url] = 0;
    }

    this.requestsInProgress[url]++;
    this.show();
  }

  hideLoaderForUrl(url: string): void {
    if (this.requestsInProgress[url]) {
      this.requestsInProgress[url]--;
      this.hide();
    } else {
      delete this.requestsInProgress[url];
    }
  }
}
