<app-global-loader></app-global-loader>

<ng-container *ngIf='isBrowserSupported'>
    <app-header *ngIf="showHeader"></app-header>

    <div [ngClass]="{'content': showHeader}">
        <router-outlet #o='outlet'></router-outlet>
    </div>

</ng-container>
